.mpage {
  position: relative;
  width: 100%;
  /* background-image: url(../../assets/m-top-bg.png), url(../../assets/m-center-bg.png), url(../../assets/m-bottom-bg.png);
	background-position: center top, left center, right bottom;
	background-size: initial , inherit, inherit;
	background-repeat: no-repeat;
	z-index: 1, 2, 3; */
}
.mpage   .mpagebg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mpage .main{
  position: relative;
  z-index: 2;
}

.mpage .main .banner{
  padding: 1.88rem .2rem 0;
}

.mpage .main .banner .title{
  margin-bottom: .8rem;
}

.mpage .main .banner .about h3{
  font-family: ARR;
  font-size: .3rem;
  font-weight: normal;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: .5rem;
}

.mpage .main .banner .about p{
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: 2.4;
  text-transform: uppercase;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.mpage .main .airdrop {
  color: #FFFFFF;
  padding: .73rem .2rem;
}

.mpage .main .airdrop .contain {
  /* padding: 0 2.29rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mpage .main .airdrop .contain .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: .35rem;
}

.mpage .main .airdrop .contain .text h2 {
  font-family: ARR;
  text-align: center;
  /* $ */
  /* JIT Airdrop */
  font-weight: 400;
  font-size: .48rem;
}

.mpage .main .airdrop .contain .text h2 span {
  font-size: .52rem;
}

.mpage .main .airdrop .contain .text p {
  font-family: ETEB;
  font-size: .14rem;
  line-height: 1.4;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: .2rem 0 .35rem;
}

.mpage .main .airdrop .contain .text .progress-box {
  /* margin-top: .33rem; */
  width: 100%;
}

.mpage .main .airdrop .contain .text .progress-box .progress {
  max-width: 2.1rem;
  width: 100%;
  height: .11rem;
  border-radius: .6rem;
  opacity: 1;
  background: #423572;
  margin: auto;
  overflow: hidden;
}

.mpage .main .airdrop .contain .text .progress-box .progress .count {
  width: 20%;
  border-radius: 60px 0px 0px 60px;
  height: 100%;
  background: #00FFFF;
}

.mpage .main .airdrop .contain .text .progress-box .num {
  font-family: GBB;
  font-size: .12rem;
  font-weight: normal;
  text-align: center;
  margin-top: .05rem;
}

.mpage .main .airdrop .contain .text .claim {
  min-width: 1.86rem;
  border-radius: .12rem;
  margin-top: .52rem;
  background: #00FFFF;
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  border: none;
  padding: .14rem .2rem;
  position: relative;
  min-height: .46rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .mpage .main .airdrop .contain .text .claim span{
  font-family: ETEB;
  font-size: .12rem;
  display: block;
  margin-top: .1rem;
} */

.mpage .main .airdrop .contain .text .claim-err{
  font-size: .12rem;
  line-height: 1.4;
  color: red;margin-top: 5px;
}

.mpage .main .airdrop .contain .pic img {
  width: 100%;
}

.mpage .main .coupon {
  margin-top: .5rem;
  position: relative;
  /* margin-bottom: 1.1rem; */
}

.mpage .main .coupon .hotAir {
  position: absolute;
  left: -34%;
  top: -3%;
  /* transform: rotate(-11.29deg); */
}

.mpage .main .coupon .hotAir img {
  width: 3.105rem;
}

.mpage .main .coupon .blockTile{
  padding: 0 .34rem;
  position: relative;
}

.mpage .main .coupon .list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 .2rem;
  padding-top: .45rem;
  position: relative;
}

.mpage .main .coupon .list .item {
  color: #000000;
  max-width: 7.51rem;
  width: 100%;
  margin: .18rem 0;
  margin-bottom: .23rem;
  position: relative;
}

.mpage .main .coupon .list .item:last-child{
  margin-bottom: 0;
}

.mpage .main .coupon .list .item .content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .13rem .24rem;
  background: url(../../assets/list_bg.png) no-repeat center;
  background-size: 100% 100%;
  z-index: 2;
}

.mpage .main .coupon .list .item .content .num {
  font-family: GBB;
  font-size: .24rem;
  font-weight: normal;
  text-align: center;
  margin-right: .17rem;
}

.mpage .main .coupon .list .item .content .text {
  font-family: ETEB;
  font-size: .09rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.4;
}

.mpage .main .coupon .list .item::after {
  content: '';
  position: absolute;
  left: .15rem;
  top: -.12rem;
  width: 100%;
  height: 100%;
  background: url(../../assets/list_bg_after.png) no-repeat center;
  background-size: 100% 100%;
}

/* .mpage .main .coupon .list .item:nth-child(2n) {
  margin-left: -2rem;
} */

.mpage .main .coupon .block {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-top: .85rem;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.mpage .main .coupon .block>div {
  background: linear-gradient(270deg, #0E7C4E -13%, #080B19 76%);
  box-sizing: border-box;
  border: .04rem solid #0FFFFF;
  border-top: none;
  max-width: 10.43rem;
  /* width: 100%; */
  width: calc(100vw - .52rem);
  position: relative;
}

.mpage .main .coupon .block>div .title {
  position: relative;
}

.mpage .main .coupon .block>div .title .title-text {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 45%, #FFFFFF 67%, #6C6C6C 87%, #D1D1D1 98%);
  position: relative;
  padding: .11rem 0 .05rem .11rem;
  font-family: GBB;
  font-size: .156rem;
  font-weight: 900;
  letter-spacing: 0em;
  color: #000000;
}

.mpage .main .coupon .block>div .title .bg {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: #0FFFFF;
}

.mpage .main .coupon .block>div .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 .17rem .24rem .24rem;
}

.mpage .main .coupon .block>div .content .subtitle {
  font-family: ARR;
  font-size: .34rem;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  transform: rotate(-15.32deg);
  margin-bottom: .2rem;
  margin-top: .2rem;
}

.mpage .main .coupon .block>div .content .subtitle span {
  position: relative;
  z-index: 2;
}

.mpage .main .coupon .block>div .content .subtitle::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: .05rem;
  background: #00C4C4;
  height: .2rem;
  width: 130%;
  transform: translateX(-50%);
}

.mpage .main .coupon .block>div .content p {
  font-family: ETEB;
  font-size: .09rem;
  font-weight: bold;
  line-height: 1.4;
  text-transform: uppercase;
  color: #FFFFFF;
}

.mpage .main .coupon .block>div .star {
  position: absolute;
  width: .756rem;
  right: 0rem;
  top: 0rem;

}

.mpage .main .coupon .block>div.mission {
  margin-left: -0.08rem;
}

.mpage .main .coupon .block>div.vision {
  background: linear-gradient(99deg, #080B19 5%, #6A0E7C 76%);
  box-sizing: border-box;
  border: 4px solid #7D10FA;
  border-top: none;
  margin-top: .41rem;
  margin-left: .58rem;
}

.mpage .main .coupon .block>div.vision .title .bg {
  background: #7D10FA;
}

.mpage .main .coupon .block>div.vision .content .subtitle::after {
  background: #7D10FA;
}

.mpage .main .coupon .block .bgstar {
  position: absolute;
  left: 0;
  top: -0.5rem;
  width: 1.14rem;
  border: none;
  background: none;
  opacity: .3;
}

.mpage .main .blockTile {
  font-family: ARR;
  font-size: .48rem;
  line-height: 1.375;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}

.mpage .main .JustIntel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../assets/m-product-bg.png) no-repeat top center;
  background-size: 7.5rem 2rem;
  background-position: 50% 2rem;
  padding-top: .8rem;
}

.mpage .main .JustIntel .blockTile {
  margin-top: 0;
  margin-bottom: .14rem;
}

.mpage .main .JustIntel .subtext {
  font-family: ETEB;
  font-size: .14rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 .32rem;
  line-height: 1.4;
}

.mpage .main .JustIntel .productlist {
  margin-top: 1.6rem;
  /* padding: 0 2.96rem 0 3.39rem; */
  padding: 0 .2rem;
  width: 100%;
  overflow: hidden;
}

.mpage .main .JustIntel .productlist .top {
  display: flex;
  align-items: flex-end;
  /* margin: 0 -.35rem; */
  flex-direction: column;
}

.mpage .main .JustIntel .productlist .top>div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  /* padding: 0 .35rem; */
}

.mpage .main .JustIntel .productlist .top>div .img {
  width: 2.0121rem;
  display: flex;
}

.mpage .main .JustIntel .productlist .top>div .text {
  flex: 1;
  font-family: ETEB;
  /* padding-right: .4rem; */
  margin-top: .45rem;
}

.mpage .main .JustIntel .productlist .top>div .text .title {
  font-size: .14rem;
  font-weight: bold;
  color: #84FFD0;
  margin-bottom: .38rem;
}

.mpage .main .JustIntel .productlist .top>div .text .subtitle {
  font-size: .12rem;
  font-weight: bold;
  line-height: 1.5;
  color: #FFFFFF;
  margin-bottom: .08rem;
}

.mpage .main .JustIntel .productlist .top>div .text p {
  font-size: .12rem;
  font-weight: bold;
  line-height: 1.5;
  color: #8C8C8C;
  margin-bottom: .28rem;
}

.mpage .main .JustIntel .productlist .top>div .text p:last-child {
  margin-bottom: 0;
}

.mpage .main .JustIntel .productlist .top>div.right {
  padding-bottom: .83rem;
  margin-top: .86rem;
}

/* .mpage .main .JustIntel .productlist .top>div.right .img {
  width: 2.4538rem;
} */

.mpage .main .JustIntel .productlist .top>div.right .text {
  /* padding: 0; */
  /* padding-left: .4rem; */
}

.mpage .main .JustIntel .productlist .center {
  /* padding: 0 2.12rem; */
  margin: 0 -0.2rem;
}

.mpage .main .JustIntel .productlist .center .list {
  /* display: flex; */
  /* justify-content: space-between; */
  padding-bottom: .3rem;
}
.mpage .main .JustIntel .productlist .center .list .swiper-pagination{
  bottom: 0px;
}

.mpage .main .JustIntel .productlist .center .list .swiper-pagination .swiper-pagination-bullet{
  width: 30px;
  height: 3px;
  border-radius: 0;
  margin: 0;
  background: #D8D8D8;
}

.mpage .main .JustIntel .productlist .center .list .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #00FFFF;
}

.mpage .main .JustIntel .productlist .center .list .item{
  
}

.mpage .main .JustIntel .productlist .center .list .item img{
  /* width: 2.01rem; */
}

.mpage .main .JustIntel .productlist .bottom {
  padding: 0 .2rem;
  display: flex;
  justify-content: flex-end;
  margin-top: .44rem;
}

.mpage .main .JustIntel .productlist .bottom .text {
  width: 100%;
  text-align: center;
  font-family: ETEB;
}

.mpage .main .JustIntel .productlist .bottom .text .title {
  margin-bottom: .24rem;
  font-size: .22rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
  line-height: 1.4;
}

.mpage .main .JustIntel .productlist .bottom .text p {
  /* padding: 0 .25rem; */
  font-size: .12rem;
  font-weight: bold;
  line-height: .18rem;
  color: #8C8C8C;
}

.mpage .main .JustIntel .digital {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: .37rem 0 .61rem;
  /* padding: 1.56rem 3.75rem 1.82rem; */
  /* display: none; */
}

.mpage .main .JustIntel .digital .text {
  flex: 1;
  font-family: ETEB;
  font-size: .14rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  padding: 0 .55rem;
  line-height: 1.4;
}

.mpage .main .JustIntel .digital .img {
  width: 4.2rem;
  position: relative;
  display: flex;
}

.mpage .main .kol {
  /* padding-left: 2.26rem; */
  /* padding-bottom: 1.43rem; */
  position: relative;
}

.mpage .main .kol .quan {
  position: absolute;
  top: 0;
  left: -0.82rem;
  width: 2.724rem;
  height: 1.32rem;
  border-radius: 50%;
  opacity: 1;

  background: rgba(27, 253, 144, 0.7);

  filter: blur(100px);
}

.mpage .main .kol .img {
  width: 140%;
  max-width: 14.18rem;
  position: relative;
  left: 45%;
  transform: translateX(-50%);
}

.mpage .main .tokenomics {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../assets/tokenomics-bg.png) no-repeat top center;
  background-size: 9.02rem 5.24rem;
  padding: 0 .2rem;
  padding-top: 1.05rem;
  margin-top: .85rem;
}

.mpage .main .tokenomics .jit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(../../assets/m-tokenomics-c-bg.png) no-repeat top center;
  background-size: 100% 100%;
  width: 100%;
  padding: 1rem 0;
  flex-direction: column;
}

.mpage .main .tokenomics .jit .title {
  font-family: ARR;
  font-size: .32rem;
  line-height: 1.4;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}

.mpage .main .tokenomics .jit p{
  font-family: ARR;
  font-size: .1rem;
  line-height: 1.4;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
  letter-spacing: 0.5em;
}

.mpage .main .tokenomics .jit .des {
  position: absolute;
  left: .58rem;
  top: .59rem;
  width: 2.95rem;
  padding: .24rem .13rem;
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 0em;
  background: url(../../assets/token-text-bg.png) no-repeat top center;
  background-size: 100% 100%;
  color: #FFFFFF;
}

.mpage .main .tokenomics .token-content{
  /* padding: 0 .45rem; */
  width: 100%;
  margin-top: 1.71rem;
}

.mpage .main .tokenomics .token-content h2{
  font-family: ARR;
  font-size: .48rem;
  font-weight: normal;
  line-height: .6rem;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: .19rem;
}

.mpage .main .tokenomics .token-content p{
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 .22rem;
}

.mpage .main .tokenomics .chat {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: .75rem 0 .1rem;
  width: 100%;
}

.mpage .main .tokenomics .chat .left {
  flex: 1;
}

/* .mpage .main .tokenomics .chat .left img {
  width: 6.9302rem;
} */

.mpage .main .tokenomics .chat .right {
  /* padding-left: 1.17rem; */
  margin-top: .46rem;
}

.mpage .main .tokenomics .chat .right .text {
  background: url(../../assets/chat-text-bg.png) no-repeat top center;
  background-size: 100% 100%;
  font-family: ETEB;
  font-size: .09rem;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  letter-spacing: 0em;
  /* width: 4.11rem; */
  color: #FFFFFF;
  padding: .33rem .14rem .13rem .35rem;
}

.mpage .main .roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: .87rem;
  padding-left: .4rem;
  padding-right: .2rem;
  box-sizing: border-box;
}

.mpage .main .roadmap .list {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: .44rem;
  background-size: 100%;
  position: relative;
}

.mpage .main .roadmap .list .item {
  /* width: 7.78rem; */
  font-family: ETEB;
  background: url(../../assets/map-bg.png) no-repeat top center;
  background-size: 100% 100%;
  padding: .19rem 0 .17rem .17rem;
  margin-bottom: .12rem;
}

.mpage .main .roadmap .list .item h4 {
  font-size: .12rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: .08rem;
  text-transform: uppercase;
}

.mpage .main .roadmap .list .item p {
  font-size: .09rem;
  font-weight: bold;
  line-height: 1.2;
  text-transform: lowercase;
  color: #2E2E2E;
}

/* .mpage .main .roadmap .list .item.item2 {
  margin-right: 1.3rem;
}

.mpage .main .roadmap .list .item.item3 {
  margin-right: 2.4rem;
}

.mpage .main .roadmap .list .item.item4 {
  margin-right: 3.7rem;
} */

.mpage .main .roadmap .list::after {
  content: '';
  position: absolute;
  left: -0.3rem;
  top: 0;
  width: .13rem;
  height: 90%;
  /* transform: rotate(27.31deg); */
  opacity: 1;
  background: url(../../assets/map-point.png) no-repeat top center;
  background-size: 100% 100%;
}

.mpage .main .ecosystem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 3.64rem 0 3.38rem; */
  margin-top: .95rem;
  /* margin-bottom: 1.48rem; */
}

.mpage .main .ecosystem .left {
  flex: 1;
}

.mpage .main .ecosystem .left .blockTile {
  text-align: center;
  padding: 0 .6rem;
  margin-bottom: .19rem;
}

.mpage .main .ecosystem .left p {
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 .6rem;
  text-align: center;
}

.mpage .main .ecosystem .right{
  margin-top: .73rem;
  padding: 0 .34rem;
}

.mpage .m-footer{
  padding: .8rem .4rem .3rem;
  position: relative;
  z-index: 99;
}

.mpage .m-footer  .logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ETEB;
  font-size: .14rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #FAFAFA;
  margin-bottom: .52rem;
}

.mpage .m-footer  .logo img{
  height: .73rem;
}

.mpage .m-footer .list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-family: ETEB; */
  font-size: .09rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: .2rem;
}

.mpage .m-footer .list a{
  color: #959595;
}

.mpage .m-footer .copy_right{
font-size: .09rem;
font-weight: bold;
text-transform: uppercase;
color: #959595;
text-align: center;
margin-top: .46rem;
}

.mpage .m-footer .social_group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpage .m-footer .social_group .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 .15rem;
  transition: all linear .5s;
}

.mpage .m-footer .social_group .item img {
  height: .3rem;
}



.mpage .bottom-quan {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  z-index: 4;
}

.mpage .bottom-quan .content{
  width: 9.82rem;
  height: 5.07rem;
  opacity: 1;
  background: #896CC2;
  filter: blur(500px);
  position: absolute;
  right: -6.22rem;
  bottom: -2.86rem;
}



.transition-all {
	transition-duration: 0.15s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}


.absolute {
	position: absolute;
}

.flex{
	display: flex;
}

.justify-center {
	justify-content: center;
}

.align-middle {
	vertical-align: middle;
}

.w-full {
	width: 100%;
}

.opacity-0 {
  opacity:0
}

.inline-block {
  display:inline-block
}

.top-0 {
  top:0
}

.left-0 {
  left:0
}

.h-full {
  height:100%
}

@keyframes spinSlowing {
  0% {
      transform:rotate(0)
  }
  to {
      transform:rotate(20turn)
  }
}
.animate-spin-slowing {
  animation:spinSlowing 10s cubic-bezier(.29, .37, .38, .44) infinite
}