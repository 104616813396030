


/* CONTAINER */

#pcontainer {
  background-color: #F7F8F9;
  padding-top:5%;
  padding-bottom:5%;
  padding-left:20%;
  padding-right:20%;
  .images {
  width: 350px;
  box-shadow: 0px 0px 6px gainsboro;
  border-radius: 10px;
  }
  span {
    font-family:'avenir-black';
    font-size: 1.0em;
    text-align: justify;
    line-height:1.5;
    margin:auto;
  }
  p {
    font-family:'avenir-light';
    font-size: 1.0em;
    text-align: justify;
    line-height:1.5;
    margin:auto;
  }
  h1 {
    font-family:'avenir-black';
    font-size: 2em;
  }
  h2 {
    font-family:'avenir-black';
    font-size: 1.0em;
  }
  .section {
    background-color: white;
    border-radius:15px;
    padding-top:2%;
    padding-bottom:2%;
    padding-left:2%;
    padding-right:2%;
  }
    
    
}


// body{
//   background-color:     #f7f8f9;
// }

@media (max-width: 640px) { /* SUR MOBILE */
  #pcontainer {
    background-color: #F7F8F9;
    padding-top:5%;
    padding-bottom:5%;
    padding-left:5%;
    padding-right:5%;
    span {
      font-family:'avenir-black';
      font-size: 0.9em;
      text-align: justify;
      line-height:1.3;
      margin:auto;
    }
    p {
      font-family:'avenir-light';
      font-size: 0.9em;
      text-align: justify;
      line-height:1.3;
      margin:auto;
    }
    h1 {
      font-family:'avenir-black';
      font-size: 1.6em;
    }
    h2 {
      font-family:'avenir-black';
      font-size: 0.9em;
    }
    .section {
      background-color: white;
      border-radius:10px;
      padding-top:3%;
      padding-bottom:3%;
      padding-left:3%;
      padding-right:3%;
    }
  }
}









#regles {
text-decoration: none;
color: #3cb2e2;
}

#email {
text-decoration: none;
color: #3cb2e2;
}







/* FOOTER */


#footer {
background-color: white;
text-align: center;
padding-top:24px;
padding-bottom:24px;
padding-left:10px;
padding-right:10px;
border-top:solid 1px whitesmoke;
}


/*#footer p {
color:#9b9b9b;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-roman';
text-decoration:none;
}*/


#footer a {
color:#262626;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:underline;
}


#footer a:hover {
color:#9c9c9c;
}


@media (max-width: 640px) { /* SUR MOBILE */
#footer a {
color:#262626;
line-height:2.5;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:underline;
}
}




