$color_1: #FFFFFF;
$color_2: #000000;
$color_3: #84FFD0;
$color_4: #8C8C8C;
$color_5: #2E2E2E;
$font-family_1: ETEB;
$font-family_2: ARR;
$font-family_3: GBB;

.page {
	position: relative;
	width: 100%;
	background-image: url(../../assets/p-top-bg.png), url(../../assets/p-center-bg.png), url(../../assets/p-bottom-bg.png);
	background-position: center top, left center, right bottom;
	background-size: 100% , 100%, 100%;
	background-repeat: no-repeat, repeat-x, repeat-y;
	z-index: 1, 2, 3;
	.home {
		width: 100%;
		.banner {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 1.27rem;
			padding-top: 1.87rem;
			padding-bottom: .27rem;
			.title {
				margin-bottom: 2.08rem;
				img {
					height: 3.8rem;
				}
			}
			.about {
				padding: 0 3.73rem;
				font-family: $font-family_1;
				font-size: .14rem;
				font-weight: bold;
				line-height: .34rem;
				text-transform: uppercase;
				color: $color_1;
				h3 {
					font-family: $font-family_2;
					font-size: .3rem;
					font-weight: normal;
					color: $color_1;
					margin-bottom: .26rem;
				}
			}
		}
		.airdrop {
			color: $color_1;
			padding: 1rem;
			.contain {
				padding: 0 2.29rem;
				display: flex;
				align-items: center;
				justify-content: center;
				.text {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					h2 {
						font-family: $font-family_2;
						text-align: center;
						font-weight: 400;
						font-size: .8rem;
						span {
							font-size: .86rem;
						}
					}
					p {
						font-family: $font-family_1;
						font-size: .22rem;
						font-weight: bold;
						text-align: center;
						text-transform: uppercase;
						margin-top: .15rem;
					}
					.progress-box {
						margin-top: .58rem;
						width: 100%;
						.progress {
							max-width: 3.48rem;
							width: 100%;
							height: .11rem;
							border-radius: .6rem;
							opacity: 1;
							background: #423572;
							margin: auto;
							overflow: hidden;
							.count {
								width: 20%;
								border-radius: 60px 0px 0px 60px;
								height: 100%;
								background: #00FFFF;
							}
						}
						.num {
							font-family: $font-family_3;
							font-size: .2rem;
							font-weight: normal;
							text-align: center;
							margin-top: .1rem;
						}
					}
					.claim {
						min-width: 3.1rem;
						border-radius: .2rem;
						margin-top: 1.2rem;
						background: #00FFFF;
						font-family: $font-family_1;
						font-size: .2rem;
						font-weight: bold;
						text-align: center;
						text-transform: uppercase;
						color: $color_2;
						line-height: 1.2;
						border: none;
						padding: .15rem .2rem;
						position: relative;
						min-height: .76rem;
					}
				}
				.pic {
					img {
						width: 7.69rem;
					}
				}
			}
		}
		.coupon {
			margin-top: 1rem;
			position: relative;
			margin-bottom: 1.1rem;
			.hotAir {
				position: absolute;
				left: 0;
				top: 0;
				img {
					width: 6.4886rem;
				}
			}
			.list {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				padding-top: .83rem;
				.item {
					color: $color_2;
					max-width: 7.51rem;
					width: 100%;
					margin: .18rem 0;
					margin-bottom: .23rem;
					position: relative;
					.content {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: .22rem .54rem .22rem .43rem;
						background: url(../../assets/list_bg.png) no-repeat center;
						background-size: 100% 100%;
						z-index: 2;
						.num {
							font-family: $font-family_3;
							font-size: .4rem;
							font-weight: normal;
							text-align: center;
							margin-right: .17rem;
						}
						.text {
							font-family: $font-family_1;
							font-size: 16px;
							font-weight: bold;
							text-transform: uppercase;
						}
					}
					&::after {
						content: '';
						position: absolute;
						left: .3rem;
						top: -.13rem;
						width: 100%;
						height: 100%;
						background: url(../../assets/list_bg_after.png) no-repeat center;
						background-size: 100% 100%;
					}
					&:nth-child(2n) {
						margin-left: -2rem;
					}
				}
			}
			.block {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				margin-top: 1.45rem;
				position: relative;
				>div {
					background: linear-gradient(270deg, #0E7C4E -13%, #080B19 76%);
					box-sizing: border-box;
					border: .04rem solid #0FFFFF;
					border-top: none;
					max-width: 10.43rem;
					width: 100%;
					position: relative;
					.title {
						position: relative;
						.title-text {
							background: linear-gradient(90deg, rgba(255, 255, 255, 0) 45%, #FFFFFF 67%, #6C6C6C 87%, #D1D1D1 98%);
							position: relative;
							padding: .11rem 0 .05rem .11rem;
							font-family: $font-family_3;
							font-size: .26rem;
							font-weight: normal;
							letter-spacing: 0em;
							color: $color_2;
						}
						.bg {
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							right: 0;
							top: 0;
							background: #0FFFFF;
						}
					}
					.content {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding: 0 .84rem .2rem .39rem;
						.subtitle {
							font-family: $font-family_2;
							font-size: .56rem;
							font-weight: normal;
							text-align: center;
							color: $color_1;
							position: relative;
							transform: rotate(-15.32deg);
							margin-bottom: .4rem;
							margin-top: .2rem;
							span {
								position: relative;
								z-index: 2;
							}
							&::after {
								content: '';
								position: absolute;
								left: 50%;
								bottom: .05rem;
								background: #00C4C4;
								height: .37rem;
								width: 120%;
								transform: translateX(-50%);
							}
						}
						p {
							font-family: $font-family_1;
							font-size: .14rem;
							font-weight: bold;
							line-height: .24rem;
							text-transform: uppercase;
							color: $color_1;
						}
					}
					.star {
						position: absolute;
						width: 1.26rem;
						right: .98rem;
						bottom: 1.14rem;
					}
				}
				>div.mission {
					margin-left: -3.10rem;
				}
				>div.vision {
					background: linear-gradient(99deg, #080B19 5%, #6A0E7C 76%);
					box-sizing: border-box;
					border: 4px solid #7D10FA;
					border-top: none;
					margin-top: .41rem;
					margin-left: 3.10rem;
					.title {
						.bg {
							background: #7D10FA;
						}
					}
					.content {
						.subtitle {
							&::after {
								background: #7D10FA;
							}
						}
					}
					.star {
						right: .21rem;
						bottom: 1.07rem;
					}
				}
				.bgstar {
					position: absolute;
					left: 2.74rem;
					bottom: 0;
					width: 1.9rem;
					border: none;
					background: none;
					opacity: .3;
				}
			}
		}
		.JustIntel {
			display: flex;
			flex-direction: column;
			align-items: center;
			background: url(../../assets/product-bg.png) no-repeat top center;
			background-size: contain;
			background-position: 0 2.43rem;
			padding-top: 1rem;
			.blockTile {
				margin-top: 0;
				margin-bottom: .24rem;
			}
			.subtext {
				font-family: $font-family_1;
				font-size: .24rem;
				font-weight: bold;
				text-align: center;
				text-transform: uppercase;
				max-width: 7.8rem;
				color: $color_1;
				line-height: 1.4;
			}
			.productlist {
				margin-top: 4.08rem;
				padding: 0 2.96rem 0 3.39rem;
				.top {
					display: flex;
					align-items: flex-end;
					margin: 0 -.35rem;
					>div {
						display: flex;
						align-items: flex-end;
						justify-content: flex-end;
						padding: 0 .35rem;
						.img {
							width: 2.0121rem;
							display: flex;
						}
						.text {
							flex: 1;
							font-family: $font-family_1;
							padding-right: .4rem;
							.title {
								font-size: .22rem;
								font-weight: bold;
								color: $color_3;
								margin-bottom: .78rem;
							}
							.subtitle {
								font-size: .14rem;
								font-weight: bold;
								line-height: .18rem;
								color: $color_1;
								margin-bottom: .08rem;
							}
							p {
								font-size: .14rem;
								font-weight: bold;
								line-height: .18rem;
								color: $color_4;
								margin-bottom: .28rem;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
					>div.right {
						padding-bottom: .78rem;
						.img {
							width: 2.4538rem;
						}
						.text {
							padding: 0;
							padding-left: .4rem;
						}
					}
				}
				.center {
					padding: 0 2.12rem;
					.list {
						display: flex;
						justify-content: space-between;
						.item {
							width: 2.01rem;
						}
						.item.item1 {
							margin-top: 1.88rem;
						}
						.item.item2 {
							margin-top: .94rem;
						}
					}
				}
				.bottom {
					padding: 0 2.12rem;
					display: flex;
					justify-content: flex-end;
					.text {
						width: 100%;
						max-width: 5.5rem;
						text-align: center;
						font-family: $font-family_1;
						.title {
							margin-bottom: .24rem;
							font-size: .22rem;
							font-weight: bold;
							text-transform: uppercase;
							color: $color_1;
						}
						p {
							padding: 0 .25rem;
							font-size: .14rem;
							font-weight: bold;
							line-height: .18rem;
							color: $color_4;
						}
					}
				}
			}
			.digital {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 1.56rem 3.75rem 1.82rem;
				.text {
					flex: 1;
					font-family: $font-family_1;
					font-size: .24rem;
					font-weight: bold;
					text-transform: uppercase;
					color: $color_1;
				}
				.img {
					width: 5.06rem;
					height: 4.8rem;
					position: relative;
					img {
						width: 7.32rem;
						// height: 7.08rem;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50% ,-50%);
					}
				}
			}
		}
		.kol {
			padding-left: 2.26rem;
			padding-bottom: 1.43rem;
			position: relative;
			.quan {
				position: absolute;
				top: 0;
				left: -1.82rem;
				width: 6.81rem;
				height: 3.3rem;
				opacity: 1;
				background: rgba(27, 253, 144, 0.3);
				filter: blur(300px);
			}
			.img {
				width: 100%;
				max-width: 14.18rem;
			}
		}
		.tokenomics {
			display: flex;
			flex-direction: column;
			align-items: center;
			background: url(../../assets/tokenomics-bg.png) no-repeat top center;
			background-size: 100%;
			padding: 0 3.0rem;
			padding-top: 1.8rem;
			.jit {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				background: url(../../assets/tokenomics-c-bg.png) no-repeat top center;
				background-size: 100% 100%;
				width: 100%;
				padding: 2.4rem 0 1.9rem;
        flex-direction: column;
				.title {
					font-family: $font-family_2;
					font-size: .8rem;
					font-weight: normal;
					text-align: center;
					color: $color_1;
          margin-bottom: .19rem;          
				}
        p{
          font-family: $font-family_1;
          font-size: .26rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.5em;
          color: #FFFFFF;
        }
				.des {
					position: absolute;
					left: .58rem;
					top: .59rem;
					width: 2.95rem;
					padding: .24rem .13rem;
					font-family: $font-family_1;
					font-size: .12rem;
					font-weight: bold;
					line-height: .22rem;
					text-transform: uppercase;
					letter-spacing: 0em;
					background: url(../../assets/token-text-bg.png) no-repeat top center;
					background-size: 100% 100%;
					color: $color_1;
				}
			}
			.jit-title{
				margin-top: 3.9rem;
				h2{
					font-family: $font-family_1;
					font-size: .8rem;
					font-weight: normal;
					text-align: center;
					line-height: 1.4;
					color: #FFFFFF;
				}
				p{
					font-family: $font-family_1;
					font-size: .16rem;
					font-weight: bold;
					text-align: center;
					text-transform: uppercase;
					line-height: 1.4;
					color: #FFFFFF;
					margin-top: .35rem;
				}
			}
			.chat {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				padding: 1.1rem 0 1.9rem;
				.left {
					flex: 1;
					img {
						width: 6.9302rem;
					}
				}
				.right {
					padding-left: 1.17rem;
					.text {
						background: url(../../assets/chat-text-bg.png) no-repeat top center;
						background-size: 100% 100%;
						font-family: $font-family_1;
						font-size: .12rem;
						font-weight: bold;
						line-height: .2rem;
						text-transform: uppercase;
						letter-spacing: 0em;
						width: 4.11rem;
						color: $color_1;
						padding: .41rem .19rem .23rem .44rem;
					}
				}
			}
		}
		.roadmap {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-left: 2.85rem;
			padding-right: 3.65rem;
			.list {
				flex-direction: column;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				width: 100%;
				margin-top: 1.07rem;
				background-size: 100%;
				position: relative;
				.item {
					width: 7.78rem;
					font-family: $font-family_1;
					background: url(../../assets/map-bg.png) no-repeat top center;
					background-size: 100% 100%;
					padding: .31rem .18rem .4rem .28rem;
					margin-bottom: .25rem;
					h4 {
						font-size: .22rem;
						font-weight: bold;
						color: $color_2;
						margin-bottom: .13rem;
						text-transform: uppercase;
					}
					p {
						font-size: .12rem;
						font-weight: bold;
						line-height: .18rem;
						text-transform: lowercase;
						color: $color_5;
					}
				}
				.item.item2 {
					margin-right: 1.3rem;
				}
				.item.item3 {
					margin-right: 2.4rem;
				}
				.item.item4 {
					margin-right: 3.7rem;
				}
				&::after {
					content: '';
					position: absolute;
					left: 7%;
					top: 0;
					width: .2197rem;
					height: 96%;
					transform: rotate(27.31deg);
					opacity: 1;
					background: url(../../assets/map-point.png) no-repeat top center;
					background-size: 100% 100%;
				}
			}
		}
		.ecosystem {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 3.64rem 0 3.38rem;
			margin-top: 2.38rem;
			margin-bottom: 1.48rem;
			.left {
				flex: 1;
				.blockTile {
					text-align: left;
				}
				p {
					font-family: $font-family_1;
					font-size: .16rem;
					font-weight: bold;
					line-height: .28rem;
					text-transform: uppercase;
					color: $color_1;
					padding-right: 1.67rem;
				}
			}
			.right {
				img {
					width: 5.02rem;
				}
			}
		}
		.blockTile {
			font-family: $font-family_2;
			font-size: .8rem;
			font-weight: normal;
			text-align: center;
			color: $color_1;
		}
		.footer {
			width: 100%;
			box-sizing: border-box;
		}
	}
	.bottom-quan {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 8rem;
		overflow: hidden;
		.content {
			width: 9.82rem;
			height: 5.07rem;
			opacity: 1;
			background: #896CC2;
			filter: blur(500px);
			position: absolute;
			right: -6.22rem;
			bottom: -2.86rem;
		}
	}
}





.transition-all {
	transition-duration: 0.15s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}


.absolute {
	position: absolute;
}

.flex{
	display: flex;
}

.justify-center {
	justify-content: center;
}

.align-middle {
	vertical-align: middle;
}

.w-full {
	width: 100%;
}

.opacity-0 {
  opacity:0
}

.inline-block {
  display:inline-block
}

.top-0 {
  top:0
}

.left-0 {
  left:0
}

.h-full {
  height:100%
}

@keyframes spinSlowing {
  0% {
      transform:rotate(0)
  }
  to {
      transform:rotate(20turn)
  }
}
.animate-spin-slowing {
  animation:spinSlowing 10s cubic-bezier(.29, .37, .38, .44) infinite
}