.mobile-header {
  width: 100vw;
  position: fixed;
  z-index: 99990;
  left: 0;
  top: 0;
  transition: all linear .5s;
}
.mheaderScroll.mobile-header {
  background: rgba(0, 0, 0, .4);
  user-select: none;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
}
.mobile-header-container {
  padding: 0.15rem 0.15rem;
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.mobile-header-container-logo {
  height: 0.54rem;
  text-align: center;
}
.mobile-header-container-logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mobile-header-container-logo a img {
  height: 100%;
}
.mobile-header-container-spec {
  flex: 1;
}
.mobile-header-container-navBtn {
  display: flex;
  align-items: center;
  /* width: 20%; */
}
.mobile-header-container .install {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mobile-header-container .install button {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  border: none;
  background-color: transparent;
}
.mobile-header .iconUl {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-header .icon ul li {
  width: 0.24rem;
  height: 0.03rem;
  background: #fff;
  transition: all 0.3s;
  /* border-radius: 0.19rem; */
}
.mobile-header .icon ul li:nth-child(2) {
  margin-bottom: 0;
  margin: 0.05rem auto;
}
.mobile-header .icon ul:hover {
  cursor: pointer;
}
.mobile-header .iconUl.active li:nth-child(2) {
  opacity: 0;
}
.mobile-header .iconUl.active li:nth-child(1) {
  transform: translateY(0.08rem) rotate(45deg);
}
.mobile-header .iconUl.active li:nth-child(3) {
  transform: translateY(-0.08rem) rotate(-45deg);
}
.phoneNav .navList {
  margin-top: 1.43rem;
  padding: 0 0.32rem;
}
.phoneNav .navList .item {
  width: 100%;
  font-size: 0.22rem;
  font-weight: bold;
  letter-spacing: 0em;
  display: block;
  padding: 0.18rem 0;
  color: #FFFFFF;
  text-decoration: none;
}
.phoneNav .navList .active a, .phoneNav .navList .active, .navList a:hover {
  /* text-decoration: underline; */
  /* text-underline-offset: 10px; */
  color: #84FFD0;
}
.nav-model {
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 9999;
  transition: all 0.3s linear;
}
.nav-model .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #080B19;
}
.nav-model.active {
  left: 0;
}
.nav-model .model-content {
  width: 100%;
  height: 100%;
  /* border-radius: 0px 0.2rem 0.2rem 0px; */
  /* background: rgba(39, 39, 39, 0.2); */
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  /* backdrop-filter: blur(20px); */
  /* -webkit-backdrop-filter: blur(20px); */
  box-sizing: border-box;
  position: relative;
}
.nav-model .model-content .share_btn {
  position: absolute;
  left: 0.28rem;
  bottom: 1rem;
  background: transparent;
  border: none;
  width: 0.48rem;
}
.nav-model .model-content .share_btn img {
  width: 100%;
}
.phoneNav {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.spec {
  flex: 1;
}
.navlist-item {
  width: 100%;
  /* height: 0.6rem; */
  margin-bottom: 0.3rem;
  display: flex;
  padding: .1rem 0;
  align-items: center;
  font-size: 0.32rem;
  justify-content: center;
  color: rgb(255, 255, 255);

  font-family: ETEB;
  font-size: .16rem;
  font-weight: bold;
  text-transform: uppercase;
  /* color: #84FFD0; */
}
/* .navlist-item:nth-last-child(1) {
  color: rgba(255, 255, 255, 0.4);
}
.navlist-item:nth-last-child(2) {
  color: rgba(255, 255, 255, 0.4);
}
.navlist-item:nth-last-child(3) {
  color: rgba(255, 255, 255, 0.4);
} */
.navlist-item-icon {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.2rem;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.navlist-item .social {
  opacity: 0.4;
}
